import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UseKeypressExample } from './use-keypress.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "usekeypress"
    }}>{`useKeypress`}</h1>
    <p>{`This hook will tell you if a key is currently being pressed and return a boolean
value indicating that.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import { useKeypress } from '@fransvilhelm/hooks';

const Indicator = ({ trackKey }) => {
  const pressing = useKeypress(trackKey);
  return <p>{pressing ? 'pressing' : 'not pressing'}</p>;
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<UseKeypressExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UseKeypressExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UseKeypressExample mdxType="UseKeypressExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`useKeypress`}</inlineCode>{` accepts one parameter – the key to look for.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`key`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{` true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The key to look for`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`useKeypress`}</inlineCode>{` returns a boolean indicating if the passed in key is currently
pressed or not.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Returns`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`pressing`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode>{` if the key is currently pressed, `}<inlineCode parentName="td">{`false`}</inlineCode>{` otherwise`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      